// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "pbjs2-master",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d201idt54vzryf.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "pbjs2dynamodb-master",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:6f41b768-eb0d-4152-9493-fa2dbf57214e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Urix5Zf5t",
    "aws_user_pools_web_client_id": "6dtl2mnrrc953j4m8cfveujs1f",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "pbjs2api",
            "endpoint": "https://nqm2qeyzml.execute-api.us-east-1.amazonaws.com/master",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
