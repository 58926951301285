import *  as React from 'react';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {Button, FormGroup, Alert} from 'reactstrap';
import {AuthUser} from './AuthUser';
import {Link, Redirect} from "react-router-dom";
import {Auth} from 'aws-amplify';
import {AuthConsumer} from "./AuthContext";
import * as Constants from "../Pages/Constants";

type Props = {
    location: any,
    children: any
};

type State = {
    toSignUpOrSignUp: boolean,
    isAjaxLoading: boolean,
    error: string | null

}

export class PBJConfirmSignUpPage extends React.Component<Props, State> {

    state = {
        toSignUpOrSignUp: false,
        isAjaxLoading: false,
        error: null
    };

    onDismissError = () => {
        this.setState({error: null});
    };

    confirmSignUp = (event: any, values: any) => {
        let user = new AuthUser(values);

        this.setState({isAjaxLoading: true, error: null});

        Auth.confirmSignUp(user.username, user.code)
            .then(data => {
                this.setState({toSignUpOrSignUp: true});
            })
            .catch(err => {
                this.setState({error: err.message});
            }).finally(() => {
            this.setState({isAjaxLoading: false});
        });
    };

    render() {

        const {from} = this.props.location.state || {from: {pathname: "/"}};
        const message = this.props.location.state ? this.props.location.state.message : null;

        const {isAjaxLoading, toSignUpOrSignUp, error} = this.state;

        if (toSignUpOrSignUp) {

            return <Redirect to={{
                pathname: '/sign-in',
                state: {
                    message: "Account confirmed! Your just seconds away from starting you list. Log in!"
                }
            }}/>
        }


        return (
            <AuthConsumer>
                {({isAuthenticated}) =>

                    isAuthenticated === Constants.Determining ? <span></span>
                        : isAuthenticated === Constants.Authenticated ? (
                            <Redirect to={from}/>
                        ) : (

                            <AvForm className="form-signin" onValidSubmit={this.confirmSignUp}>
                                <h2>Confirm Account</h2>
                                <Alert color="primary" isOpen={message !== null}> {message} </Alert>
                                <Alert color="danger" isOpen={error !== null}
                                       toggle={this.onDismissError}> {error} </Alert>
                                <AvField autoFocus type="text" name="username" required placeholder="Username"/>
                                <AvField type="text" name="code" placeholder="Authorization Code" required/>
                                <Button color="link" to="/resend-sign-up" tag={Link}>Didn't get it? Send it
                                    again.</Button>
                                <FormGroup>
                                    <Button block>{isAjaxLoading ? Constants.Loader : 'Confirm Account!'}</Button>

                                </FormGroup>
                            </AvForm>

                        )
                }
            </AuthConsumer>
        );
    }
}
