import *  as React from 'react';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {Button, FormGroup, Alert} from 'reactstrap';
import {AuthUser} from './AuthUser';
import {Link, Redirect} from "react-router-dom";
import {AuthConsumer} from "./AuthContext";
import * as Constants from "../Pages/Constants";

type Props = {
    location: any,
    children: any
};

type State = {
    isAjaxLoading: boolean,
    error: string | null
}

export class PBJSignInPage extends React.Component<Props, State> {

    state = {
        isAjaxLoading: false,
        error: null
    };

    onDismissError = () => {
        this.setState({error: null});
    };

    handleSignIn = (event: any, values: any, signIn: (user: AuthUser) => Promise<Response>) => {
        const user = new AuthUser(values);
        this.setState({isAjaxLoading: true, error: null});

        signIn(user).catch(err => {
            this.setState({error: err.message});
        }).finally(() => {
            this.setState({isAjaxLoading: false});
        });

    };

    render() {
        const {from} = this.props.location.state || {from: {pathname: "/"}};
        const message = this.props.location.state ? this.props.location.state.message : null;
        const {isAjaxLoading, error} = this.state;

        // if (toConfirmSignUp) {
        //     return <Redirect to={{
        //         pathname: '/',
        //         state: {
        //             message: "You're signed it!"
        //         }
        //     }}/>
        // }

        return (
            <AuthConsumer>
                {({isAuthenticated, signIn}) =>

                    isAuthenticated === Constants.Determining ? <span></span>
                        : isAuthenticated === Constants.Authenticated ? (
                            <Redirect to={from}/>
                        ) : (

                            <AvForm className="form-signin"
                                onValidSubmit={(events, values) => this.handleSignIn(events, values, signIn)}>
                                <h2>Login to our site</h2>
                                <Alert color="primary" isOpen={message !== null}> {message} </Alert>
                                <Alert color="danger" isOpen={error !== null}
                                       toggle={this.onDismissError}> {error} </Alert>
                                <AvField autoFocus id="existingUsername" name="username" required
                                         placeholder="Username"/>
                                <AvField id="existingPassword" type="password" name="password"
                                         placeholder="Password" required/>
                                <Button color="link" to="/forgot-password" tag={Link}>Forgot your password?</Button>
                                <FormGroup>
                                    <Button block>{isAjaxLoading ? Constants.Loader : 'Sign in!'}</Button>
                                </FormGroup>
                            </AvForm>
                        )
                }
            </AuthConsumer>
        );
    }
}
