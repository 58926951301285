import * as React from 'react';
import * as Constants from './Constants'
import * as Utils from './Utils'
import {Item} from "./Item";
import {Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, ButtonGroup} from 'reactstrap';
import {AvForm, AvField, AvInput} from 'availity-reactstrap-validation';

type Props = {

    context: Constants.Context,

    deleteItem: (any) => Promise<Item | string>,
    updateItem: (any) => Promise<Item | string>,
    createItem: (any) => Promise<Item | string>,
    publishItem: (any) => Promise<Item | string>,
    claimItem: (any) => Promise<Item | string>,
    unclaimItem: (any) => Promise<Item | string>,
}

type State = {
    modal: boolean,
    item?: Item | null,
    error: string | null,
    isAjaxLoading: boolean
}

export class PBJModalForm extends React.Component<Props, State> {
    state = {
        item: null,
        modal: false,
        error: null,
        isAjaxLoading: false
    };

    // shouldComponentUpdate(nextProps: Props) {
    //     return true;
    //     const {modal} = this.props;
    //     return modal !== nextProps.modal;
    // }

    openModal = (item: Item): void => {
        this.setState({modal: true, item: item});
    };

    onDismissError = () => {

    };


    handleDeleteItem = () => {
        const {deleteItem} = this.props;
        const {item} = this.state;

        this.onDismissError();
        this.setState({error: null});

        deleteItem(item)
            .then(() => this.setState({modal: false}))
            .catch((error) => this.setState({error: Utils.parseError(error)}));

    };

    handleCreateItem = (event: any, values: any) => {
        const {createItem} = this.props;
        const item = new Item(values);
        this.onDismissError();

        createItem(item)
            .then(() => this.setState({modal: false}))
            .catch((error) => this.setState({error: Utils.parseError(error)}));
    };

    handlePublishItem = () => {
        const {publishItem, item} = this.props;
        this.onDismissError();

        publishItem(item)
            .then(() => this.setState({modal: false}))
            .catch((error) => this.setState({error: Utils.parseError(error)}));
    };

    handleUpdateItem = (event: any, values: any) => {
        const {updateItem} = this.props;
        const item: Item = new Item(values);
        this.onDismissError();

        updateItem(item)
            .then(() => this.setState({modal: false}))
            .catch((error) => this.setState({error: Utils.parseError(error)}));
    };

    handleClaimItem = () => {
        const {claimItem} = this.props;
        const {item} = this.state;
        this.onDismissError();

        claimItem(item)
            .then(() => this.setState({modal: false}))
            .catch((error) => this.setState({error: Utils.parseError(error)}));
    };

    handleUnclaimItem = () => {
        const {unclaimItem} = this.props;
        const {item} = this.state;
        this.onDismissError();

        unclaimItem(item)
            .then(() => this.setState({modal: false}))
            .catch((error) => this.setState({error: Utils.parseError(error)}));
    };

    handlePublishItem = () => {
        const {publishItem} = this.props;
        const {item} = this.state;
        this.onDismissError();

        publishItem(item)
            .then(() => this.setState({modal: false}))
            .catch((error) => this.setState({error: Utils.parseError(error)}));
    };

    handleModalClosed = (): void => {
        this.setState({item: null});
    };

    handleToggleModal = (): void => {
        this.setState({modal: false});
    };

    render() {

        let actionBarKey = 0;
        let actionBar = [];
        let formSubmitAction;
        let formHeader;
        let formFieldsKey = 0;
        let formFields = [];

        const {context} = this.props;
        const {item, error, modal} = this.state;

        formFields.push(<ItemId key={formFieldsKey++}/>);
        formFields.push(<ItemOwnerId key={formFieldsKey++}/>);
        formFields.push(<ItemStatus key={formFieldsKey++}/>);
        formFields.push(<ItemVersion key={formFieldsKey++}/>);

        if (item && item.ownerId) {
            if (context === Constants.Wish) {
                if (item.status === Constants.Published) {
                    formSubmitAction = (e) => e.preventDefault();
                    formHeader = "Here's something you're wishing for!";
                    formFields.push(<ItemName key={formFieldsKey++} disabled={true}/>);
                    formFields.push(<ItemDescription key={formFieldsKey++} disabled={true}/>);
                    if (item.url) {
                        formFields.push(<ItemUrlNavigatable key={formFieldsKey++} url={item.url}/>);
                    }
                } else if (item.status === Constants.Draft) {
                    formHeader = "What do you wish for?";
                    formSubmitAction = this.handleUpdateItem;
                    formFields.push(<ItemName key={formFieldsKey++}/>);
                    formFields.push(<ItemDescription key={formFieldsKey++}/>);
                    formFields.push(<ItemUrlEditable key={formFieldsKey++}/>);

                    actionBar.push(<UpdateItem key={actionBarKey++}/>);
                    actionBar.push(<PublishItem key={actionBarKey++} onClick={this.handlePublishItem}
                                                id={item.ownerId}/>);
                    actionBar.push(<DeleteItem key={actionBarKey++} onClick={this.handleDeleteItem}
                                               id={item.ownerId}/>);
                }
            } else if (context === Constants.Shop) {
                if (item.status === Constants.Available) {
                    formHeader = item.ownerName + " wants a ...";
                    formSubmitAction = this.handleClaimItem;
                    actionBar.push(<ClaimItem key={actionBarKey++} id={item.ownerId}/>);

                    formFields.push(<ItemName key={formFieldsKey++} disabled={true}/>);
                    formFields.push(<ItemDescription key={formFieldsKey++} disabled={true}/>);
                    if (item.url) {
                        formFields.push(<ItemUrlNavigatable key={formFieldsKey++} url={item.url}/>);
                    }
                } else if (item.status === Constants.Claimed) {
                    formHeader = "Looks like someone's already getting " + item.ownerName + " a...";
                    formFields.push(<ItemName key={formFieldsKey++} disabled={true}/>);
                    formFields.push(<ItemDescription key={formFieldsKey++} disabled={true}/>);
                    if (item.url) {
                        formFields.push(<ItemUrlNavigatable key={formFieldsKey++} url={item.url}/>);
                    }
                }
            } else if (context === Constants.Cart) {
                if (item.status === Constants.Claimed) {
                    formHeader = "You're getting this for " + item.ownerName + "!";
                    formSubmitAction = this.handleUnclaimItem;
                    actionBar.push(<UnclaimItem key={actionBarKey++} id={item.ownerId}/>);

                    formFields.push(<ItemName key={formFieldsKey++} disabled={true}/>);
                    formFields.push(<ItemDescription key={formFieldsKey++} disabled={true}/>);
                    if (item.url) {
                        formFields.push(<ItemUrlNavigatable key={formFieldsKey++} url={item.url}/>);
                    }
                }
            }

        } else {
            if (context === Constants.Wish) {
                formHeader = "What do you wish for?";
                formSubmitAction = this.handleCreateItem;
                actionBar.push(<CreateItem key={actionBarKey++}/>);

                formFields.push(<ItemName key={formFieldsKey++}/>);
                formFields.push(<ItemDescription key={formFieldsKey++}/>);
                formFields.push(<ItemUrlEditable key={formFieldsKey++}/>);
            }
        }

        console.debug("Added %d actions and %d fields", actionBarKey, formFieldsKey);
        let formBody = (
            <React.Fragment>
                <Alert color="danger" isOpen={error !== null} toggle={this.onDismissError}> {error} </Alert>
                <AvForm id="giftForm" onValidSubmit={formSubmitAction} model={item}>
                    {formFields.map(item => {
                        return item;
                    })}
                </AvForm>
            </React.Fragment>);


        return (<Modal isOpen={modal} toggle={this.handleToggleModal} onClosed={this.handleModalClosed}>
            <ModalHeader toggle={this.handleToggleModal}>{formHeader}</ModalHeader>
            <ModalBody>
                {formBody}
            </ModalBody>
            <ModalFooter>
                <div>
                    <ButtonGroup>
                        {actionBar.map(item => {
                            return (item)
                        })}
                        <Button onClick={this.handleToggleModal}>Close</Button>
                    </ButtonGroup>
                </div>
            </ModalFooter>
        </Modal>);
    }
}


const ItemId = (props: any) => (
    <AvInput type="hidden" name="id" {...props} />);

const ItemOwnerId = (props: any) => (
    <AvInput type="hidden" name="ownerId" {...props} />);

const ItemStatus = (props: any) => (
    <AvField type="hidden" name="status" {...props}/>);

const ItemVersion = (props: any) => (
    <AvField type="hidden" name="version" {...props}/>);

const ItemName = (props: any) => (
    <AvField type="text" placeholder="Socks" label="Item Name" name="name" required maxLength="200"
             errorMessage="Name is missing or is invalid" {...props} />);

const ItemDescription = (props: any) => (
    <AvField type="textarea" placeholder="My amazing description." label="Description"
             name="description" required maxLength="200"
             errorMessage="Description is missing or is invalid" {...props}/>);

const ItemUrlEditable = (props: any) => (
    <AvField type="url" name="url" label="URL" placeholder="https://example.com" maxLength="200"
             pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
             errorMessage="URL does not appear valid. Did you forget http(s)://?" {...props}/>);

const ItemUrlNavigatable = (props: any) => (
    <FormGroup>
        <Label for="url">Checkout the link to the item...</Label> {' '}
        <a href={props.url} target="_blank">Check it out!</a>
    </FormGroup>);

const UpdateItem = (props: any) => (
    <Button form="giftForm" type="submit" color="primary" {...props}>Update</Button>);

const CreateItem = (props: any) => (
    <Button form="giftForm" type="submit" color="primary" {...props}>Create</Button>);

const PublishItem = (props: any) => (
    <Button data-id={props.id} color="success"  {...props}>Publish</Button>);

const DeleteItem = (props: any) => (
    <Button data-id={props.id} color="danger" {...props}>Delete</Button>);

const ClaimItem = (props: any) => (
    <Button form="giftForm" type="submit" color="primary" {...props}>Claim</Button>);

const UnclaimItem = (props: any) => (
    <Button form="giftForm" type="submit" color="primary" {...props}>Unclaim</Button>);

