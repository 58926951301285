import ScaleLoader from 'react-spinners/ScaleLoader';
import * as React from "react";

export type Status = "Draft" | "Published" | "Available" | "Claimed";
export type Context = "Wish" | "Shop" | "Cart";
export type AuthPhase = "Determining" | "Authenticated" | "Not Authenticated";
export type Action = 'Update' | 'Publish' | 'Claim' | 'Unclaim';

export const Draft: Status = "Draft";
export const Published: Status = "Published";
export const Available: Status = "Available";
export const Claimed: Status = "Claimed";

export const Wish: Context = "Wish";
export const Shop: Context = "Shop";
export const Cart: Context = "Cart";

export const Determining: AuthPhase = "Determining";
export const Authenticated: AuthPhase = "Authenticated";
export const NotAuthenticated: AuthPhase = "Not Authenticated";

export const Update: Action = 'Update';
export const Publish: Action = 'Publish';
export const Claim: Action = 'Claim';
export const Unclaim: Action = 'Unclaim';

export const basePath = "/items";
export const apiName = 'pbjs2api';

export const Loader = <ScaleLoader
    height={6}
    width={4}
    margin={"2px"}
    radius={1}
    color={"white"}
    loading={true}
/>;