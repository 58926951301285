import * as React from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";
import {PBJNavBar} from "./Pages/PBJNavBar"
import {WishPage, CartPage, ShopPage} from "./Pages/PBJPage";
import {AuthProvider, AuthConsumer} from './Security/AuthContext';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import {PBJSignInPage} from "./Security/PBJSignInPage";
import {PBJSignUpPage} from "./Security/PBJSignUpPage";
import {PBJConfirmSignUpPage} from "./Security/PBJConfirmSignUpPage";
import {PBJResendSignUpPage} from "./Security/PBJResendSignUpPage";
import {PBJConfirmPasswordPage} from "./Security/PBJConfirmPasswordPage";
import {PBJForgotPasswordPage} from "./Security/PBJForgotPasswordPage";
import * as Constants from "./Pages/Constants";
import {Jumbotron, Card, Col, CardTitle, CardText, Row} from "reactstrap";

Amplify.configure(aws_exports);

type Props = {};

class App extends React.Component<Props> {

  render() {

    console.debug("Rendering App");

    return (
        <AuthProvider>

          <PBJNavBar/>

          <PrivateRoute exact path="/wish" component={WishPage}/>
          <PrivateRoute path="/shop" component={ShopPage}/>
          <PrivateRoute path="/cart" component={CartPage}/>

          <Route path="/sign-in" component={PBJSignInPage}/>
          <Route path="/sign-up" component={PBJSignUpPage}/>
          <Route path="/confirm-sign-up" component={PBJConfirmSignUpPage}/>
          <Route path="/resend-sign-up" component={PBJResendSignUpPage}/>
          <Route path="/forgot-password" component={PBJForgotPasswordPage}/>
          <Route path="/confirm-password" component={PBJConfirmPasswordPage}/>
          <Route exact path="/" component={Home}/>

        </AuthProvider>
    );
  }
}

// class PrivateRoute extends React.Component<Props> {
//     render() {}
// }

const PrivateRoute = ({component: Component, ...rest}) => (
    <AuthConsumer>
      {({isAuthenticated}) =>
          <Route
              {...rest}
              render={props =>
                  isAuthenticated === Constants.Determining ? <span></span>
                      : isAuthenticated === Constants.Authenticated ? (<Component {...props} />) : (
                          <Redirect
                              to={{
                                pathname: "/sign-in",
                                state: {from: props.location}
                              }}
                          />
                      )
              }
          />
      }
    </AuthConsumer>
);

const Home = () => (<React.Fragment>
      <Jumbotron>
        <h1 className="display-3">Merry Christmas!</h1>
        <p className="lead">See you all soon digitally via Zoom!</p>
      </Jumbotron>
      <Row>
        <Col sm="6">
          <Card body className="mb-4 shadow-sm">
            <CardTitle>What's this all about?</CardTitle>
            <CardText>
              Holiday shopping time is upon us! It's time to make your Christmas list, check it twice and
              publish
              it for others to see. Use this portal to help curate your perfect wish list and to choose what
              to
              purchase others. As always, choosing from the list is optional. We decided to make this app so
              that
              we can attempt to decrease the amount of madness that regularly goes into our holiday visits.
              Also,
              if you're wondering.. no, the PBJ in 'PBJ Santa' does not stand for Peanut Butter & Jelly... it
              stands for, 'Pannenko, Babicz and Jereb'. :)</CardText>
          </Card>
        </Col>
        <Col sm="6">
          <Card body className="mb-4 shadow-sm">
            <CardTitle>How to use the site</CardTitle>
            <ul>
              <li>Wish - Create your christmas list and publish it for others to see. Once you publish <b>an
                item</b>, you
                can no longer edit that item however the rest of your list can still be modified!
              </li>
              <li>Shop - View items from the your family's published lists and claim items that you'd like to
                get for them.
              </li>
              <li>Cart - See what you're getting others and un-claim any items so that the item can go back
                into the pool for others to choose from.
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
);

export default App;