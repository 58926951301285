import * as React from 'react';
import * as Constants from './Constants'
import {Item} from "./Item";
import {Alert, AlertList} from "react-bs-notifier";
import {PBJModalForm} from "./PBJModalForm";
import {PBJTable} from "./PBJTable";
import update from "immutability-helper";
import {API, Auth} from 'aws-amplify';

type Props = {
    name: string,
    tableColumns: Array<string | React.Element<'th'>>,
    tableRows: Array<string | (any) => React.Element<'td'>>,
    context: Constants.Context,
    isCreateItem?: boolean,
    isFilterByText?: boolean,
    isHideStatus?: boolean,
    statusToHide?: Constants.Status,
    title: string,
    description: string
};

type State = {
    item?: ?Item,
    alerts: Array<any>,
    items: Array<Item>,
    loading: boolean
};

class PBJPage extends React.Component<Props, State> {

    modalRef: any = React.createRef();

    state = {
        item: null,
        items: [],
        alerts: [],
        loading: true

    };

    /** LOAD DATA METHODS **/

    componentDidMount = async () => {
        this.getItems()
            .catch((error) => this.addAlert("Oh no!", error.message, "danger"));
    };

    /** E/O LOAD DATA METHODS **/

    /** ALERT METHODS **/

    addAlert = (headline: string, message: string, type: ?string = "success"): void => {
        const {alerts} = this.state;
        let newAlert = {
            id: (new Date()).getTime(),
            type: type,
            headline: headline,
            message: message
        };

        this.setState({
            alerts: [...alerts, newAlert]
        });
    };

    onAlertDismissed = (alert: Alert): void => {
        const {alerts} = this.state;
        const idx = alerts.indexOf(alert);
        if (idx >= 0) {
            this.setState({
                alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
            });
        }
    };

    /** E/O ALERT METHODS **/

    /** FORM METHODS **/

    getItems = async (): Promise<Item[] | string> => {
        const {context} = this.props;

        this.setState({items: [], loading: true});
        let apiName = Constants.apiName;
        let path = Constants.basePath;
        let myInit = {
            headers: {'PBJS-Context': context}
        };

        let items = [];

        try {
            const response = await API.get(apiName, path, myInit);
            response.data.Items.forEach(function (item) {
                items.push(new Item(item));
            });
        } finally {
            this.setState({items: items, loading: false});
        }

        return JSON.stringify(items);

    };

    createItem = async (createItem: Item): Promise<Item | string> => {
        const {items} = this.state;

        let apiName = Constants.apiName;
        let path = Constants.basePath;
        let myInit = {
            body: createItem,
            // We need this information only here because the JWT stores User info (email, name, etc...)
            headers: {'PBJS-Token': (await Auth.currentSession()).idToken.jwtToken}
        };
        const response = await API.post(apiName, path, myInit);
        const createdItem = response.data;

        const newItems = update(items, {$push: [createdItem]});
        this.setState({"items": newItems});

        this.addAlert("Created " + createdItem.name, "Go add something else now!");
        return createdItem

    };

    deleteItem = async (deleteItem: Item): Promise<Item | string> => {
        const {items} = this.state;


        let apiName = Constants.apiName;
        let path = Constants.basePath + '/object/' + deleteItem.ownerId + '/' + deleteItem.id;

        const response = await API.del(apiName, path);
        const deletedItem = response.data;

        const index = items.findIndex(item => item.ownerId === deletedItem.ownerId && item.id === deletedItem.id);
        const newItems = update(items, {$splice: [[index, 1]]});
        this.setState({items: newItems});

        this.addAlert("Removed " + deleteItem.name, "Now go add something new to the list!");
        return deletedItem;

    };

    patchItem = async (patchItem: Item, action: Constants.Action): Promise<Item | string> => {
        const {items} = this.state;

        let apiName = Constants.apiName;
        let path = Constants.basePath;
        let myInit = {
            body: patchItem,
            headers: {'PBJS-Action': action}
        };
        const response = await API.patch(apiName, path, myInit);

        // TODO: I don't like this response type
        const patchedItem: Item = response.data.Attributes;

        let newItems;
        const index = items.findIndex(item => item.ownerId === patchedItem.ownerId && item.id === patchedItem.id);

        if (action === Constants.Unclaim) {
            newItems = update(items, {$splice: [[index, 1]]});
        } else {
            newItems = update(items, {[index]: {$set: patchedItem}});
        }
        this.setState({items: newItems});

        return patchedItem;

    };

    // TODO for some reason, when we do a publish click, the row already is published so it won't refresh.
    updateItem = async (updateItem: Item): Promise<Item | string> => {
        const updatedItem = await this.patchItem(updateItem, Constants.Update);
        this.addAlert("Updated " + updateItem.name, "Now go add something new to the list!");
        return updatedItem;
    };

    publishItem = async (publishItem: Item): Promise<Item | string> => {
        const publishedItem = await this.patchItem(publishItem, Constants.Publish);
        this.addAlert("Published " + publishItem.name, "Now go add something new to the list!");
        return publishedItem;
    };

    claimItem = async (claimItem: Item): Promise<Item | string> => {
        const claimedItem = await this.patchItem(claimItem, Constants.Claim);
        this.addAlert("Claimed " + claimItem.name, "Now go claim another item!");
        return claimedItem;
    };

    unclaimItem = async (unclaimItem: Item): Promise<Item | string> => {
        const unclaimedItem = await this.patchItem(unclaimItem, Constants.Unclaim);
        this.addAlert("Unclaimed " + unclaimItem.name, "Now go claim another item!");
        return unclaimedItem;
    };

    openModal = (id?: string, ownerId?: string): void => {
        const {items} = this.state;
        let item;
        if ((id === null || id === undefined) && (ownerId === null || ownerId === undefined)) {
            item = new Item();
        } else {
            item = items.find(function (item: Item) {
                return item.id === id && item.ownerId === ownerId;
            });
        }

        this.modalRef.current.openModal(item);

    };

    /** E/O FORM METHODS **/

    render() {

        console.debug('Rendering Table');
        const {title, description, tableColumns, tableRows, context, isCreateItem, isFilterByText, isHideStatus, statusToHide} = this.props;
        const {loading, items, item, alerts} = this.state;

        return (
            <div>

                <AlertList
                    position="bottom-right"
                    alerts={alerts}
                    timeout={3000}
                    onDismiss={this.onAlertDismissed}
                />

                <PBJTable
                    columns={tableColumns}
                    rows={tableRows}
                    items={items}

                    isCreateItem={isCreateItem}
                    isFilterByText={isFilterByText}
                    isHideStatus={isHideStatus}
                    statusToHide={statusToHide}

                    openModal={this.openModal}
                    getItems={this.getItems}
                    addAlert={this.addAlert}

                    title={title}
                    description={description}
                    loading={loading}
                />

                <PBJModalForm
                    ref={this.modalRef}

                    deleteItem={this.deleteItem}
                    updateItem={this.updateItem}
                    createItem={this.createItem}
                    publishItem={this.publishItem}
                    claimItem={this.claimItem}
                    unclaimItem={this.unclaimItem}

                    item={item}
                    context={context}

                />

            </div>
        );
    }
}

export const WishPage = () => (<PBJPage

    tableColumns={["Name", "Description", "Status"]}
    tableRows={["name", "description", "status"]}
    context={Constants.Wish}

    isHideStatus={true}
    isCreateItem={true}
    statusToHide={Constants.Published}

    title={"Your Wishlist!"}
    description={"Use the form below to create your Christmas list. Modify it as much as you want. When you are finished, click publish and it will become available for others to see. Because each item can be published separately from one another, publish early and often!"}

    name={"Wish"}

/>);

export const ShopPage = () => (<PBJPage

    tableColumns={["Owner", "Name", "Description", "Status"]}
    tableRows={["ownerName", "name", "description", "status"]}
    context={Constants.Shop}

    isHideStatus={true}
    statusToHide={Constants.Claimed}
    isFilterByText={true}

    title={"Let's go Shopping!"}
    description={"Take a peek at what everyone has on their lists. Click on an item to view additional details and then, if the item has not been claimed yet, you'll have a choice to get it for them. After you claim an item, it'll appear in your cart. If you claimed something by accident, go to your cart and un-claim it. As an FYI, you won't see your items on this list, but don't worry. If your list is published, others will see your items!"}


    name={"Shop"}

/>);

export const CartPage = () => (<PBJPage

    tableColumns={["Owner", "Name", "Description"]}
    tableRows={["ownerName", "name", "description"]}
    context={Constants.Cart}

    title={"Your Cart!"}
    description={"Review the items you are buying. Click on any item to view additional information and to be given an option to un-claim it."}

    name={"Cart"}

/>);
