export class AuthUser {
    sub: string;
    name: string;
    email: string;
    username: string;
    password: string;
    code: string;
    jwt: string;

    constructor(data: any) {
        let _data = data || {};
        this.sub = _data.sub;
        this.name = data.name;
        this.email = data.email;
        this.username = _data.username;
        this.password = _data.password;
        this.code = _data.code;
        this.jwt = _data.jwt;
    }

}
