import type {Status} from './Constants'

export class Item {

    id: string;
    name: string;
    description: string;
    url: string;

    ownerId: string;
    ownerName: string;

    groupId: string;

    claimedById: string;

    status: Status;
    version: number;

    constructor(data: any) {
        let _data = data || {};
        this.id = _data.id;
        this.name = _data.name;
        this.description = _data.description;
        this.url = _data.url;

        this.ownerId = _data.ownerId;
        this.ownerName = _data.ownerName;

        this.groupId = _data.groupId;

        this.claimedById = _data.claimedById;

        this.status = _data.status;
        this.version = _data.version;

    }

    equalTo(otherItem: Item) {
        return this.id === otherItem.id &&
            this.name === otherItem.name &&
            this.description === otherItem.description &&
            this.url === otherItem.url &&
            this.ownerId === otherItem.ownerId &&
            this.ownerName === otherItem.ownerName &&
            this.groupId === otherItem.groupId &&
            this.claimedById === otherItem.claimedById &&
            this.status === otherItem.status &&
            this.version === otherItem.version;
    }

}