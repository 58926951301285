import *  as React from 'react';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {AuthUser} from './AuthUser';
import {Button, FormGroup, Alert} from 'reactstrap';
import {Link, Redirect} from "react-router-dom";
import {Auth} from 'aws-amplify';
import {AuthConsumer} from "./AuthContext";
import * as Constants from "../Pages/Constants";

type Props = {
    location: any,
    children: any
};

type State = {
    toConfirmSignUp: boolean,
    isAjaxLoading: boolean,
    error: string | null
}

export class PBJResendSignUpPage extends React.Component<Props, State> {

    state = {
        toConfirmSignUp: false,
        isAjaxLoading: false,
        error: null
    };

    onDismissError = () => {
        this.setState({error: null});
    };

    handleResendSignUp = (event: any, values: any) => {
        let user = new AuthUser(values);

        this.setState({isAjaxLoading: true, error: null});

        Auth.resendSignUp(user.username, user.code)
            .then(() => {
                this.setState({toConfirmSignUp: true});
            })
            .catch(err => {
                this.setState({error: err.message});
            }).finally(() => {
            this.setState({isAjaxLoading: false});
        });

    };

    render() {
        console.debug("Rendering PBJResendSignUpPage");
        const {from} = this.props.location.state || {from: {pathname: "/"}};
        const {toConfirmSignUp, isAjaxLoading, error} = this.state;
        const message = this.props.location.state ? this.props.location.state.message : null;

        if (toConfirmSignUp) {
            return <Redirect to={{
                pathname: '/confirm-sign-up',
                state: {
                    message: "A new confirmation code has been sent to your inbox."
                }
            }}/>
        }

        return (
            <AuthConsumer>
                {({isAuthenticated}) =>

                    isAuthenticated === Constants.Determining ? <span></span>
                        : isAuthenticated === Constants.Authenticated ? (
                            <Redirect to={from}/>
                        ) : (
                            <AvForm className="form-signin" onValidSubmit={this.handleResendSignUp}>
                                <h2>Resend confirmation code!</h2>
                                <Alert color="primary" isOpen={message !== null}> {message} </Alert>
                                <Alert color="danger" isOpen={error !== null}
                                       toggle={this.onDismissError}> {error} </Alert>
                                <AvField autoFocus type="text" name="username" required placeholder="Username"/>
                                <Button color="link" to="/confirm-sign-up" tag={Link}>Oh wait, you already have a
                                    code?</Button>

                                <FormGroup>
                                    <Button block>{isAjaxLoading ? Constants.Loader : 'Confirm!'}</Button>
                                </FormGroup>
                            </AvForm>
                        )
                }
            </AuthConsumer>
        );
    }
}
