import *  as React from 'react';
import {AuthUser} from './AuthUser';
//$FlowFixMe
import {Auth} from 'aws-amplify';
import * as Constants from "../Pages/Constants";

type MyContext = {
    isAuthenticated: Constants.AuthPhase,
    authUser: AuthUser | null,
    signOut: (cb?: () => void) => Promise<Response>,
    signIn: (user: AuthUser) => Promise<Response>,
};

const defaultState: MyContext = {
    isAuthenticated: Constants.Determining,
    authUser: null,
    signOut: (cb?: () => void) => Promise.reject('Fix function!'),
    signIn: (user: AuthUser) => Promise.reject('Fix function!')
};

const AuthContext = React.createContext(defaultState);

class AuthProvider extends React.Component<any, MyContext> {
    state = {
        isAuthenticated: Constants.Determining,
        signOut: (cb?: () => void): Promise<Response> => {
            return Auth.signOut()
                .then(() => cb && cb())
                .finally(() => this.setState({authUser: null, isAuthenticated: Constants.NotAuthenticated}));
        },
        signIn: (user: AuthUser): Promise<Response> => {
            return Auth.signIn(user.username, user.password)
                .then(user => {
                    const authUser = new AuthUser({
                        jwtToken: user.signInUserSession.idToken.jwtToken,
                        sub: user.signInUserSession.idToken.payload.sub,
                        email: user.signInUserSession.idToken.payload.email,
                        username: user.signInUserSession.idToken['cognito:username'],
                        name: user.signInUserSession.idToken.payload.name
                    });

                    this.setState({authUser: authUser, isAuthenticated: Constants.Authenticated});
                    return user;
                })
                .catch(err => {
                    this.setState({authUser: null, isAuthenticated: Constants.NotAuthenticated});
                    throw err;
                });
        },
        authUser: null
    };

    async componentDidMount() {
        try {
            const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

            if (currentAuthenticatedUser) {
                const authUser = new AuthUser({
                    jwtToken: currentAuthenticatedUser.signInUserSession.idToken.jwtToken,
                    sub: currentAuthenticatedUser.signInUserSession.idToken.payload.sub,
                    email: currentAuthenticatedUser.signInUserSession.idToken.payload.email,
                    username: currentAuthenticatedUser.signInUserSession.idToken['cognito:username'],
                    name: currentAuthenticatedUser.signInUserSession.idToken.payload.name
                });

                this.setState({authUser: authUser, isAuthenticated: Constants.Authenticated});
            }
            else {
                this.setState({authUser: null, isAuthenticated: Constants.NotAuthenticated});
            }
        }
        catch (error) {
            this.setState({authUser: null, isAuthenticated: Constants.NotAuthenticated});
            console.error(error);
        }
    }

    render() {

        const {children} = this.props;

        return (
            <AuthContext.Provider
                value={this.state}>
                {children}
            </AuthContext.Provider>
        )
    }
}

const AuthConsumer = AuthContext.Consumer;

export {AuthProvider, AuthConsumer}