import React from 'react';
import {
    Collapse,
    Navbar,
    NavbarText,
    NavbarToggler,
    NavbarBrand,
    Nav, NavLink,
    NavItem
} from 'reactstrap';
import {Link, NavLink as RRNavLink, withRouter} from "react-router-dom";
import {AuthConsumer} from '../Security/AuthContext';
import * as Constants from "./Constants";
import logo from '../logo.png';

type Props = {};

type State = {
    isOpen: boolean
};

const navText = {
    paddingBottom: '8px',
    paddingTop: '8px',
    fontSize: '16px'
}

const navLinkStyle = {
    cursor: 'pointer'
};

export class PBJNavBar extends React.Component<Props, State> {

    state = {
        isOpen: false
    };

    toggle = (): void => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    navigateToHome = (history: any): void => {
        history.push("/");
    };

    render() {
        console.debug("Rendering PBJNavBar");
        const {isOpen} = this.state;

        const NavItems = withRouter(
            ({history}) =>

                <AuthConsumer>
                    {({isAuthenticated, signOut, authUser}) => (
                        (isAuthenticated === Constants.Determining ? <span></span> :
                            isAuthenticated === Constants.Authenticated ? (
                                <Nav className="ml-auto" navbar>
                                    <NavbarBrand style={navText}>{(authUser && authUser.name) ? authUser.name : 'You'}'s ready to...</NavbarBrand>
                                    <NavItem>
                                        <NavLink to="/wish" activeClassName="active" tag={RRNavLink}>WISH</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/shop" activeClassName="active" tag={RRNavLink}>SHOP</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/cart" activeClassName="active" tag={RRNavLink}>CART</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink style={navLinkStyle}
                                                 onClick={() => signOut(this.navigateToHome(history))}>LOGOUT</NavLink>
                                    </NavItem>
                                </Nav>
                            ) : (
                                <Nav className="ml-auto" navbar>
                                    <NavItem>
                                        <NavLink to="/sign-in" activeClassName="active" tag={RRNavLink}>SIGN IN
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink to="/sign-up" activeClassName="active" tag={RRNavLink}>SIGN UP
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            ))
                    )}
                </AuthConsumer>
        );

        return (

            <Navbar light expand="sm">
                <NavbarBrand to="/" tag={Link}>
                    <img src={logo} width="30" height="30" className="d-inline-block align-top" alt=""/> PBJ Santa
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle}/>
                <Collapse isOpen={isOpen} navbar>
                    <NavItems/>
                </Collapse>

            </Navbar>
        )

    }
}