import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// import registerServiceWorker from './registerServiceWorker';
import './bootstrap.min.css';
import {BrowserRouter as Router} from "react-router-dom";

const root: ?Element = document.getElementById('root');

if (root != null) {
    ReactDOM.render(<Router><App/></Router>, root);
    // registerServiceWorker();
}