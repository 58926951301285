import *  as React from 'react';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {Button, FormGroup, Alert} from 'reactstrap';
import {AuthUser} from './AuthUser';
import {Link, Redirect} from "react-router-dom";
import {Auth} from 'aws-amplify';
import {AuthConsumer} from "./AuthContext";
import * as Constants from "../Pages/Constants";

type Props = {
    location: any,
    children: any
};

type State = {
    toConfirmPassword: boolean,
    isAjaxLoading: boolean,
    error: string | null

}

export class PBJForgotPasswordPage extends React.Component<Props, State> {

    state = {
        toConfirmPassword: false,
        isAjaxLoading: false,
        error: null
    };

    onDismissError = () => {
        this.setState({error: null});
    };

    handleForgotPassword = (event: any, values: any) => {
        let user = new AuthUser(values);
        this.setState({isAjaxLoading: true, error: null});
        Auth.forgotPassword(user.username)
            .then(() => {
                this.setState({toConfirmPassword: true});
            })
            .catch(err => {
                this.setState({error: err.message});
            }).finally(() => {
                this.setState({isAjaxLoading: false});
            }
        );
    };

    render() {
        const {from} = this.props.location.state || {from: {pathname: "/"}};
        const message = this.props.location.state ? this.props.location.state.message : null;
        const {toConfirmPassword, isAjaxLoading, error} = this.state;

        if (toConfirmPassword) {
            return <Redirect to={{
                pathname: '/confirm-password',
                state: {
                    message: "Whoosh, password reset code sent to your inbox! Grab it and come back here to set yourself up w/ a new password."
                }
            }}/>
        }

        return (
            <AuthConsumer>
                {({isAuthenticated}) =>

                    isAuthenticated === Constants.Determining ? <span></span>
                        : isAuthenticated === Constants.Authenticated ? (
                            <Redirect to={from}/>
                        ) : (
                            <AvForm className="form-signin" onValidSubmit={this.handleForgotPassword}>
                                <h2>Request Password Reset</h2>
                                <Alert color="primary" isOpen={message !== null}> {message} </Alert>
                                <Alert color="danger" isOpen={error !== null}
                                       toggle={this.onDismissError}> {error} </Alert>
                                <AvField autoFocus name="username" required placeholder="Username"/>
                                <Button color="link" to="/confirm-password" tag={Link}>Already have a reset code?</Button>
                                <FormGroup>
                                    <Button
                                        block>{isAjaxLoading ? Constants.Loader : 'Request Password Reset!'}</Button>
                                </FormGroup>
                            </AvForm>
                        )
                }
            </AuthConsumer>
        );
    }
}
