import *  as React from 'react';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {Button, FormGroup, Alert} from 'reactstrap';
import {AuthUser} from './AuthUser';
import {Link, Redirect} from "react-router-dom";
import {AuthConsumer} from "./AuthContext";
import * as Constants from "../Pages/Constants";
//$FlowFixMe
import {Amplify, Auth} from "aws-amplify";
import aws_exports from "../aws-exports";

type Props = {
    location: any,
    children: any
};

type State = {
    isAjaxLoading: boolean,
    error: string | null,
    toConfirmSignUp: boolean
}

export class PBJSignUpPage extends React.Component<Props, State> {

    state = {
        isAjaxLoading: false,
        error: null,
        toConfirmSignUp: false
    };

    onDismissError = () => {
        this.setState({error: null});
    };

    handleSignUp = (event: any, values: any) => {
        const user = new AuthUser(values);

        this.setState({isAjaxLoading: true, error: null});

        Auth.signUp({
            username: user.username,
            password: user.password,
            attributes: {email: user.email, name: user.name}
        }).then(() => {
            this.setState({toConfirmSignUp: true});
        }).catch(err => {
            this.setState({error: err.message});
        }).finally(() => {
            this.setState({isAjaxLoading: false});
        });

    };

    render() {
        const {from} = this.props.location.state || {from: {pathname: "/"}};
        const message = this.props.location.state ? this.props.location.state.message : null;
        const {isAjaxLoading, toConfirmSignUp, error} = this.state;

        if (toConfirmSignUp) {
            return <Redirect to={{
                pathname: '/confirm-sign-up',
                state: {
                    message: "Awesome, now check your email to confirm your account!"
                }
            }}/>
        }

        return (
            <AuthConsumer>
                {({isAuthenticated, signIn}) =>

                    isAuthenticated === Constants.Determining ? <span></span>
                        : isAuthenticated === Constants.Authenticated ? (
                            <Redirect to={from}/>
                        ) : (
                            <AvForm className="form-signin" onValidSubmit={this.handleSignUp}>
                                <h2>Sign up now</h2>
                                <Alert color="primary" isOpen={message !== null}> {message} </Alert>
                                <Alert color="danger" isOpen={error !== null}
                                       toggle={this.onDismissError}> {error} </Alert>
                                <AvField autoFocus name="username" placeholder="Username" required
                                         helpMessage="Used to login with."/>
                                <AvField name="name" placeholder="Display Name" required
                                         helpMessage="What others will see your posts under."/>
                                <AvField name="email" placeholder="Email" required
                                         helpMessage="Where password resets and notifications will be sent."
                                         validate={{
                                             email: {
                                                 value: true,
                                                 errorMessage: "That doesn't look like an email!"
                                             }
                                         }}/>

                                <AvField type="password" name="password" placeholder="Password"
                                         required helpMessage="Your password must be 8-20 characters long, contain letters and numbers, and must
                not contain spaces, special characters, or emoji."/>

                                <AvField type="password" name="confirmPassword" placeholder="Confirm Password"
                                         required
                                         validate={{
                                             match: {
                                                 value: 'password',
                                                 errorMessage: "Password does not match!"
                                             }
                                         }}/>

                                <Button color="link" to="/confirm-sign-up" tag={Link}>Have a confirmation code
                                    already?</Button>
                                <FormGroup>
                                    <Button block>{isAjaxLoading ? Constants.Loader : 'Sign me up!'}</Button>
                                </FormGroup>
                            </AvForm>
                        )
                }
            </AuthConsumer>
        );
    }
}
