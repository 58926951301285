export const parseError = (error: any): string => {
    let errorMessage = '';
    if (error && error.response && error.response.data && error.response.data.error) {
        if (error.response.data.error.code === "ConditionalCheckFailedException") {
            errorMessage = "Wait, you may have some stale data... refresh the list any try again."
        } else {
            errorMessage = error.response.data.error.message;
        }
    } else if (typeof error === "string") {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = "Oh oh, something went really wrong!"
    }

    return errorMessage;
};