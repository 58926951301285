import * as React from 'react';
import {Item} from "./Item";
import * as Constants from "./Constants";
import * as Utils from "./Utils";
import {
    Table,
    Form,
    Button,
    FormGroup,
    Input,
    ButtonGroup,
    Collapse,
    Card, CardBody, Row, Col, Container
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

// type Prop = {}
//
// type ToolbarState = {
// checkedItems: Array<string>
// }
//
// function withToolbar(WrappedComponent) {
//
//     return class extends React.Component<TableProps, ToolbarState> {
//
// state = {
//     checkedItems: []
// };
//
// handleInputChange = (event: any): void => {
//     const target = event.target;
//     const value = target.type === 'checkbox' ? target.checked : target.value;
//     const name = target.name;
//
//     this.setState({
//         [name]: value
//     });
// };
//
// handleCheckRow = (event: any): void => {
//     const {checkedItems} = this.state;
//
//     const target = event.target;
//     const id = target.parentElement.parentElement.dataset.id;
//
//     const index = checkedItems.findIndex(checkedItemId => checkedItemId === id);
//
//     // If it's not found, then add it as a checked row
//     if (index === -1) {
//         const newCheckedItems = [...checkedItems, id];
//         this.setState({checkedItems: newCheckedItems});
//     }
//
//     // Otherwise, remove it from the checked items
//     else {
//         const newCheckedItems = [...checkedItems.slice(0, index), ...checkedItems.slice(index + 1)];
//         this.setState({checkedItems: newCheckedItems});
//     }
//
//     event.stopPropagation();
//
// };
//
// handlePublishItems = (): void => {
//     const {checkedItems} = this.state;
//     const {items} = this.props;
//
//     const updateItem = (any) => {
//     };
//
//     if (checkedItems.length !== 0) {
//         let publishCount = 0;
//         checkedItems.forEach(checkedItem => {
//
//             let publishItem: ?Item = items.find(item => {
//                 return item.id === checkedItem;
//             });
//
//             if (publishItem && publishItem.status !== Constants.Published) {
//                 publishItem.status = Constants.Published;
//                 updateItem(publishItem);
//                 publishCount++;
//             }
//         });
//
//         if (publishCount) {
//             // this.addAlert("Published  " + publishCount + " items", "Now check it out at the bottom of the list");
//             console.log("Published  " + publishCount + " items", "Now check it out at the bottom of the list");
//         }
//     }
//
//     this.setState({checkedItems: []});
//
// };
//
// isChecked = (event: any): boolean => {
//     const target = event.target;
//     const id = target.parentElement.parentElement.dataset.id;
//
//     const {checkedItems} = this.state;
//     return checkedItems.findIndex(checkedItemId => checkedItemId === id) !== -1
// };
//
// render() {
//
//     const {columns, rows, handleToggleModal} = this.props;

// const newColumns = [...columns];
// const newRows = [...rows];
//
// const checkboxHeader = (<th key={-2}/>);
// newColumns.unshift(checkboxHeader);
//
// const checkboxRow = (item: Item) => (
//     <td data-id={item.id} key={-2} scope="row">
//         {item.status === Constants.Published ? '' : <input
//             type="checkbox"
//             className="checkbox"
//             checked={this.isChecked}
//             onChange={this.handleCheckRow}
//         />}
//     </td>);
//
// newRows.unshift(checkboxRow);
//
//             return (
//                 <React.Fragment>
//                     <Form>
//                         <FormGroup>
//                             <Button color="primary" onClick={handleToggleModal}>Create</Button>
//                             {/*<Button color="success" onClick={this.handlePublishItems}>Publish</Button>*/}
//                         </FormGroup>
//                     </Form>
//                     <WrappedComponent
//                         {...this.props}
//                         // columns={newColumns}
//                         // rows={newRows}
//                     />
//                 </React.Fragment>);
//         }
//
//     }
// }

type FilterState = {
    isStatusHidden: boolean,
    filterText: string,
    filterCollapsed: boolean
}

function withFilter(WrappedComponent) {


    return class extends React.Component<TableProps, FilterState> {

        state = {
            isStatusHidden: false,
            filterText: "",
            filterCollapsed: true
        };

        toggleFilterNavbar = () => {
            this.setState({filterCollapsed: !this.state.filterCollapsed});
        }

        filterText = (item: Item, filterText: string): boolean => {
            return ((item.name != null && item.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
                (item.description != null && item.description.toLowerCase().indexOf(filterText.toLowerCase()) !== -1) ||
                (item.ownerName != null && item.ownerName.toLowerCase().indexOf(filterText.toLowerCase()) !== -1));

        };

        hideStatus = (item: Item, hideStatus?: Constants.Status): boolean => {
            return (item.status && item.status === hideStatus)
        };

        handleInputChange = (event: any): void => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            this.setState({
                [name]: value
            });
        };

        handleStatusHidden = (event: any): void => {
            const hide = event.target.dataset.hide === 'true';
            this.setState({isStatusHidden: hide});
        };

        render() {
            const {items, isFilterByText, isHideStatus, statusToHide} = this.props;
            const {filterText, isStatusHidden} = this.state;
            let rows = items.filter((item) => {
                let result = true;
                result = (isFilterByText && filterText.length !== 0 ? result && this.filterText(item, filterText) : result);
                result = (isHideStatus && isStatusHidden ? result && !this.hideStatus(item, statusToHide) : result);
                return result;
            });

            let filter;
            if (!(isFilterByText || isHideStatus)) {
                filter = '';
            } else {
                filter = (

                    <div className={"text-right"}>

                        <Button onClick={this.toggleFilterNavbar}>Filter</Button>
                        <Collapse isOpen={!this.state.filterCollapsed} navbar>

                            <Card>
                                <CardBody>

                                    {isFilterByText ? (
                                        <FormGroup>
                                            <Input name="filterText" type="search" placeholder="Search"
                                                   value={filterText} onChange={this.handleInputChange}/>
                                        </FormGroup>
                                    ) : ''}

                                    {isHideStatus ? (

                                        <ButtonGroup>

                                            <Button outline data-hide={true} onClick={this.handleStatusHidden}
                                                    active={isStatusHidden}
                                                    onMouseDown={e => e.preventDefault()}>Hide {statusToHide} </Button>
                                            <Button outline data-hide={false} onClick={this.handleStatusHidden}
                                                    active={!isStatusHidden}
                                                    onMouseDown={e => e.preventDefault()}>Show {statusToHide}</Button>
                                        </ButtonGroup>

                                    ) : ''}
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                );
            }
            return (
                <WrappedComponent  {...this.props} filter={filter} items={rows}/>
            );
        }
    }
}

type TableProps = {
    columns: Array<string | React.Element<'th'>>,
    rows: Array<string | (any) => React.Element<'td'>>,
    items: Array<Item>,
    rowProps?: any,
    openModal: (id?: string, ownerId?: string) => void,

    // TODO these are actually FilterProps but I don't know how to Flow these
    isFilterByText?: boolean,
    isCreateItem?: boolean,
    isHideStatus?: boolean,
    statusToHide?: Constants.Status,

    getItems: (any) => Promise<Item[] | string>,
    addAlert: (string, string, ?string) => void,

    filter?: any,
    loading: boolean,

    title: string,
    description: string
};

class PBJTableInternal extends React.Component<TableProps> {

    skeletonLoader = [];

    constructor(props: TableProps) {
        super(props);

        for (let i = 0; i < 10; i++) {
            let col = [];
            for (let j = 0; j < props.columns.length; j++) {
                col.push(<td key={i + '' + j}><Skeleton width={(Math.random() * .3 + .7) * 100 + '%'}/></td>);
            }
            this.skeletonLoader.push(<tr key={i}>{col}</tr>);
        }
    }

    handleModalOpen = () => {
        const {openModal} = this.props;
        openModal();
    };

    handleRefreshList = () => {
        const {getItems, addAlert} = this.props;
        getItems()
            .catch((error) => addAlert("Oh no!", Utils.parseError(error), "danger"));

    };

    render() {

        console.debug('Rendering PBJTable');
        const {title, description, loading, filter, items, columns, rows, openModal, isCreateItem} = this.props;
        let columnComponents: any = [];

        // TODO this is narly


        columns.forEach((column, i) => {
            columnComponents.push(<th key={i} scope="col" className="w-25">{column}</th>);
        });


        return (
            <Container className="mt-sm-3">
                <Row>
                    <Col>

                        <h1>{title}</h1>
                        <p>{description}</p>
                    </Col>
                </Row>

                <Form className="mt-sm-3">
                    <div className={"row"}>
                        <div className={"col"}>
                            <FormGroup>
                                <ButtonGroup>
                                    {isCreateItem ? <React.Fragment>
                                            <Button color="primary" onClick={this.handleModalOpen}>Create</Button> {' '}
                                        </React.Fragment>
                                        : ''}
                                    <Button onClick={this.handleRefreshList}>Refresh</Button>
                                </ButtonGroup>

                            </FormGroup>


                        </div>
                        <div className={"col"}> {filter}</div>


                    </div>
                </Form>

                <div className={"table-responsive-sm"}>
                    <Table className="table  ">
                        <thead>
                        <tr>
                            {columnComponents.map(column => {
                                return (column);
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {loading ?
                            this.skeletonLoader.map((loader, i) => {
                                return loader;
                            }) :

                            items && items.length > 0 ?
                                items.map((item, i) => {
                                    return (
                                        <MyRow key={i}
                                               item={item}
                                               rows={rows}
                                               openModal={openModal}
                                        />
                                    );
                                }) : <tr>
                                    <td colSpan={columns.length}>Oh oh, there's nothing here!</td>
                                </tr>
                        }
                        </tbody>
                    </Table>
                </div>
            </Container>);
    }
}

type RowProps = {
    item: Item,
    rows: Array<string | (any) => React.Element<'td'>>,
    openModal: (id?: string, ownerId?: string) => void
};

class MyRow extends React.Component<RowProps> {

    // TODO for some reason, when we do a publish click, the row already is published so it won't refresh. Thus commenting this out.
    // shouldComponentUpdate(nextProps: RowProps) {
    //     const {item} = this.props;
    //     return !item.equalTo(nextProps.item);
    // }

    handleModalOpen = (event: any) => {
        const {openModal} = this.props;
        const id = event.target.parentElement.dataset.id;
        const ownerId = event.target.parentElement.dataset.ownerId;
        openModal(id, ownerId);
    };

    render() {
        const {item, rows} = this.props;
        console.debug('Rendering Row > ' + JSON.stringify(this.props, null, 2));
        return (
            <tr data-owner-id={item.ownerId} data-id={item.id} onClick={this.handleModalOpen}>
                {rows.map((row, i) => {
                    // TODO this is narlyish
                    if (typeof row === "string") {
                        // TODO not sure why it's saying something about lack of index

                        return <td
                            // $FlowFixMe
                            key={i}>{item[row] && item[row].length > 50 ? item[row].substring(0, 50) + '...' : item[row]}</td>;
                    } else {
                        return row(item);
                    }

                })}
            </tr>
        );
    }
}

// export const PBJTableWithFilterAndToolbar = withToolbar(withFilter(PBJTable));
export const PBJTable = withFilter(PBJTableInternal);