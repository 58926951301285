import *  as React from 'react';
import {AvForm, AvField} from 'availity-reactstrap-validation';
import {Button, FormGroup, Alert} from 'reactstrap';
import {AuthUser} from './AuthUser';
import {Link, Redirect} from "react-router-dom";
import {Auth} from 'aws-amplify';
import {AuthConsumer} from "./AuthContext";
import * as Constants from "../Pages/Constants";

type Props = {
    location: any,
    children: any
};

type State = {
    toSignUpOrSignUp: boolean,
    isAjaxLoading: boolean,
    error: string | null
}

export class PBJConfirmPasswordPage extends React.Component<Props, State> {

    state = {
        toSignUpOrSignUp: false,
        isAjaxLoading: false,
        error: null
    };

    onDismissError = () => {
        this.setState({error: null});
    };

    handleForgotPasswordSubmit = (event: any, values: any) => {
        let user = new AuthUser(values);
        this.setState({isAjaxLoading: true, error: null});
        Auth.forgotPasswordSubmit(user.username, user.code, user.password)
            .then(() => {
                this.setState({toSignUpOrSignUp: true});
            })
            .catch(err => {
                this.setState({error: err.message});
            }).finally(() => {
            this.setState({isAjaxLoading: false});
        });

    };

    render() {
        const {from} = this.props.location.state || {from: {pathname: "/"}};
        const message = this.props.location.state ? this.props.location.state.message : null;
        const {isAjaxLoading, toSignUpOrSignUp, error} = this.state;

        if (toSignUpOrSignUp) {
            return <Redirect to={{
                pathname: '/sign-in',
                state: {
                    message: "New password assigned, go ahead and log in with it. (Now, this time don't forget it!)"
                }
            }}/>
        }

        return (

            <AuthConsumer>
                {({isAuthenticated}) =>

                    isAuthenticated === Constants.Determining ? <span></span>
                        : isAuthenticated === Constants.Authenticated ? (
                            <Redirect to={from}/>
                        ) : (
                            <AvForm className="form-signin" onValidSubmit={this.handleForgotPasswordSubmit}>
                                <h2>Reset your password!</h2>
                                <Alert color="primary" isOpen={message !== null}> {message} </Alert>
                                <Alert color="danger" isOpen={error !== null}
                                       toggle={this.onDismissError}> {error} </Alert>
                                <AvField autoFocus name="username" placeholder="Username" required/>
                                <AvField name="code" placeholder="Code" required/>
                                <AvField type="password" name="password" placeholder="Password"
                                         required helpMessage="Your password must be 8-20 characters long, contain letters and numbers, and must
                                not contain spaces, special characters, or emoji."/>

                                <AvField type="password" name="confirmPassword" placeholder="Confirm Password"
                                         required
                                         validate={{
                                             match: {
                                                 value: 'password',
                                                 errorMessage: "Password does not match!"
                                             }
                                         }}/>
                                <Button color="link" to="/forgot-password" tag={Link}>Need to send a new
                                    code?</Button>
                                <FormGroup>
                                    <Button block>{isAjaxLoading ? Constants.Loader : 'Update Password!'}</Button>
                                </FormGroup>
                            </AvForm>
                        )
                }
            </AuthConsumer>
        );
    }
}
